
// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide Function

$( document ).ready(function() { 
	
	$(window).scroll(function() {    
		var scroll = $(window).scrollTop();
		if (scroll >= 50) {
			$("#navbar-brand-container").addClass("active");
		}else{
			$("#navbar-brand-container").removeClass("active");
		}
	}); //missing );
	
	function progress() {
	
	  var windowScrollTop = $(window).scrollTop();
	  var docHeight = $(document).height();
	  var windowHeight = $('.block_tpw-timeline').height();
	  var progress = ((windowScrollTop / (docHeight - windowHeight)) * 100) * 1.5;
	  var $bgColor = progress > 99 ? '#074858' : '#074858';
	  var $textColor = progress > 99 ? '#fff' : '#333';
	
	  $('.block_tpw-timeline .progress .bar').width(progress + '%').css({ backgroundColor: $bgColor });
	  $('.block_tpw-timeline .fill').height(progress + '%').css({ backgroundColor: $bgColor });
	}
	
	progress();
	
	$(document).on('scroll', progress);

	$('.slider-is-kundenliste').each(function( index ) {
	  $(this).slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			arrows : false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 2000,
			responsive: [
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				  }
				},
				{
				  breakpoint: 600,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				  }
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			  ]
	  });
	});
	$('.newsslider').each(function( index ) {
	  $(this).slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			//arrows : false,
			prevArrow: $('#prev-arrow'),
			nextArrow: $('#next-arrow'),
			infinite: true,
			autoplay: false,
			swipeToSlide: false,
			swipe: false,
			autoplaySpeed: 2000,
			responsive: [
				{
				  breakpoint: 600,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				  }
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			  ]
	  });
	});
	if ($(window).width() > 600) {
	   var sWidth = $('.newsslider > div').outerWidth();
	   var cWidth = (sWidth / 3) * 2;
	   var nWidth = sWidth / 3;
	   setTimeout(function() {
		   $('.newsslider > div .slick-current').attr('style', 'width: '+cWidth+'px !important');
		   $('.newsslider > div .slick-current').next().attr('style', 'width: '+nWidth+'px !important'); 
	   }, 1000);
	   	$('.newsslider').parent().find('.slider-arrow').on( "click", function() {
			setTimeout(function() {
		    	$('.newsslider > div .slick-current').attr('style', 'width: '+cWidth+'px !important');
				$('.newsslider > div .slick-current').next().attr('style', 'width: '+nWidth+'px !important');
			}, 610);
	   	});
	}
	
	$('.customerslider').each(function( index ) {
	  $(this).slick({
			slidesToShow: 2,
			slidesToScroll: 1,
			arrows : true,
			infinite: true,
			autoplay: false,
			autoplaySpeed: 2000,
			responsive: [
				{
				  breakpoint: 600,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				  }
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			  ]
	  });
	});
	$('.referenzenslider').each(function( index ) {
	  $(this).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots : true,
			arrows : false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 2000,
			responsive: [
				{
				  breakpoint: 600,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				  }
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			  ]
	  });
	});
	
	if ($(window).width() > 800) {
	   var sWidth = $('.referenzenslider > div').outerWidth();
	   var cWidth = (sWidth / 3) * 2.25;
	   
	   setTimeout(function() {
		   $('.referenzenslider > div .slick-current').attr('style', 'width: '+cWidth+'px !important');
		   
	   }, 1000);
		   $('.referenzenslider').parent().find('.slider-arrow').on( "click", function() {
			setTimeout(function() {
				$('.referenzenslider > div .slick-current').attr('style', 'width: '+cWidth+'px !important');
				
			}, 610);
		   });
	}

	setTimeout(function(){ 
		var height = $('.newsslider').height();
		$('.newsslider').css('height' , height);
		var height2 = $('.referenzenslider').height();
		$('.referenzenslider').css('height' , height2);
		$('.wsf-form #wsf-1-field-wrapper-7').addClass('d-flex align-items-end');
		$('.wsf-form #wsf-1-field-wrapper-14').addClass('d-flex align-items-end');
		$('.aside .wsf-form #wsf-1-field-wrapper-7').append('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.148" height="26.054" style="margin-left: 15px;" viewBox="0 0 19.148 26.054"><defs><clipPath id="clip-path"><rect id="Rechteck_1400" data-name="Rechteck 1400" width="19.148" height="26.054" transform="translate(0 0)" fill="none"></rect></clipPath></defs><g id="Gruppe_55" data-name="Gruppe 55" transform="translate(0 0)"><g id="Gruppe_53" data-name="Gruppe 53" clip-path="url(#clip-path)"><path id="Pfad_86" data-name="Pfad 86" d="M5.767,25.7,18.441,13.027,5.767.354" fill="none" stroke="#074858" stroke-miterlimit="10" stroke-width="1"></path><line id="Linie_10" data-name="Linie 10" x1="17.923" transform="translate(0 13.008)" fill="none" stroke="#074858" stroke-miterlimit="10" stroke-width="1"></line></g></g></svg>');
		
		$('.aside .wsf-form #wsf-1-field-wrapper-14').append('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.148" height="26.054" style="margin-left: 15px;" viewBox="0 0 19.148 26.054"><defs><clipPath id="clip-path"><rect id="Rechteck_1400" data-name="Rechteck 1400" width="19.148" height="26.054" transform="translate(0 0)" fill="none"></rect></clipPath></defs><g id="Gruppe_55" data-name="Gruppe 55" transform="translate(0 0)"><g id="Gruppe_53" data-name="Gruppe 53" clip-path="url(#clip-path)"><path id="Pfad_86" data-name="Pfad 86" d="M5.767,25.7,18.441,13.027,5.767.354" fill="none" stroke="#074858" stroke-miterlimit="10" stroke-width="1"></path><line id="Linie_10" data-name="Linie 10" x1="17.923" transform="translate(0 13.008)" fill="none" stroke="#074858" stroke-miterlimit="10" stroke-width="1"></line></g></g></svg>');
		
		$('.aside .wsf-form #wsf-1-fields-1').append('<div class="col-auto ms-0 ms-lg-auto"><a class="btn btn-read-more" target="_blank" href="https://outlook.office.com/bookwithme/user/b6d51180383f4528af077dac764d412e%40tp-weber.com/">Termin buchen <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.148" height="26.054" viewBox="0 0 19.148 26.054"><defs><clipPath id="clip-path"><rect id="Rechteck_1400" data-name="Rechteck 1400" width="19.148" height="26.054" transform="translate(0 0)" fill="none"></rect></clipPath></defs><g id="Gruppe_55" data-name="Gruppe 55" transform="translate(0 0)"><g id="Gruppe_53" data-name="Gruppe 53" clip-path="url(#clip-path)"><path id="Pfad_86" data-name="Pfad 86" d="M5.767,25.7,18.441,13.027,5.767.354" fill="none" stroke="#074858" stroke-miterlimit="10" stroke-width="1"></path><line id="Linie_10" data-name="Linie 10" x1="17.923" transform="translate(0 13.008)" fill="none" stroke="#074858" stroke-miterlimit="10" stroke-width="1"></line></g></g></svg></a></div>');
		
		$('.aside .wsf-form #wsf-1-fields-2').append('<div class="col-auto ms-0 ms-lg-auto"><a class="btn btn-read-more" style="font-size:18px;" target="_blank" href="https://outlook.office.com/bookwithme/user/b6d51180383f4528af077dac764d412e%40tp-weber.com/">Book an appointment <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.148" height="26.054" viewBox="0 0 19.148 26.054"><defs><clipPath id="clip-path"><rect id="Rechteck_1400" data-name="Rechteck 1400" width="19.148" height="26.054" transform="translate(0 0)" fill="none"></rect></clipPath></defs><g id="Gruppe_55" data-name="Gruppe 55" transform="translate(0 0)"><g id="Gruppe_53" data-name="Gruppe 53" clip-path="url(#clip-path)"><path id="Pfad_86" data-name="Pfad 86" d="M5.767,25.7,18.441,13.027,5.767.354" fill="none" stroke="#074858" stroke-miterlimit="10" stroke-width="1"></path><line id="Linie_10" data-name="Linie 10" x1="17.923" transform="translate(0 13.008)" fill="none" stroke="#074858" stroke-miterlimit="10" stroke-width="1"></line></g></g></svg></a></div>');
		
		
		
		$('.block_tpw-form.bg-dunkelblau .wsf-form #wsf-1-field-wrapper-7').append('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.148" height="26.054" style="margin-left: 15px;" viewBox="0 0 19.148 26.054"><defs><clipPath id="clip-path"><rect id="Rechteck_1400" data-name="Rechteck 1400" width="19.148" height="26.054" transform="translate(0 0)" fill="none"></rect></clipPath></defs><g id="Gruppe_55" data-name="Gruppe 55" transform="translate(0 0)"><g id="Gruppe_53" data-name="Gruppe 53" clip-path="url(#clip-path)"><path id="Pfad_86" data-name="Pfad 86" d="M5.767,25.7,18.441,13.027,5.767.354" fill="none" stroke="#da935c" stroke-miterlimit="10" stroke-width="1"></path><line id="Linie_10" data-name="Linie 10" x1="17.923" transform="translate(0 13.008)" fill="none" stroke="#da935c" stroke-miterlimit="10" stroke-width="1"></line></g></g></svg>');
		
		$('.block_tpw-form.bg-dunkelblau .wsf-form #wsf-1-field-wrapper-14').append('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.148" height="26.054" style="margin-left: 15px;" viewBox="0 0 19.148 26.054"><defs><clipPath id="clip-path"><rect id="Rechteck_1400" data-name="Rechteck 1400" width="19.148" height="26.054" transform="translate(0 0)" fill="none"></rect></clipPath></defs><g id="Gruppe_55" data-name="Gruppe 55" transform="translate(0 0)"><g id="Gruppe_53" data-name="Gruppe 53" clip-path="url(#clip-path)"><path id="Pfad_86" data-name="Pfad 86" d="M5.767,25.7,18.441,13.027,5.767.354" fill="none" stroke="#da935c" stroke-miterlimit="10" stroke-width="1"></path><line id="Linie_10" data-name="Linie 10" x1="17.923" transform="translate(0 13.008)" fill="none" stroke="#da935c" stroke-miterlimit="10" stroke-width="1"></line></g></g></svg>');
		
		$('.block_tpw-form.bg-dunkelblau .wsf-form #wsf-1-fields-1').append('<div class="col-auto ms-0 ms-lg-auto"><a class="btn btn-read-more" target="_blank" href="https://outlook.office.com/bookwithme/user/b6d51180383f4528af077dac764d412e%40tp-weber.com/">Termin buchen <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.148" height="26.054" viewBox="0 0 19.148 26.054"><defs><clipPath id="clip-path"><rect id="Rechteck_1400" data-name="Rechteck 1400" width="19.148" height="26.054" transform="translate(0 0)" fill="none"></rect></clipPath></defs><g id="Gruppe_55" data-name="Gruppe 55" transform="translate(0 0)"><g id="Gruppe_53" data-name="Gruppe 53" clip-path="url(#clip-path)"><path id="Pfad_86" data-name="Pfad 86" d="M5.767,25.7,18.441,13.027,5.767.354" fill="none" stroke="#da935c" stroke-miterlimit="10" stroke-width="1"></path><line id="Linie_10" data-name="Linie 10" x1="17.923" transform="translate(0 13.008)" fill="none" stroke="#da935c" stroke-miterlimit="10" stroke-width="1"></line></g></g></svg></a></div>');
		 
		$('.block_tpw-form.bg-dunkelblau .wsf-form #wsf-1-fields-2').append('<div class="col-auto ms-0 ms-lg-auto"><a class="btn btn-read-more" target="_blank" href="https://outlook.office.com/bookwithme/user/b6d51180383f4528af077dac764d412e%40tp-weber.com/">Book an appointment  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19.148" height="26.054" viewBox="0 0 19.148 26.054"><defs><clipPath id="clip-path"><rect id="Rechteck_1400" data-name="Rechteck 1400" width="19.148" height="26.054" transform="translate(0 0)" fill="none"></rect></clipPath></defs><g id="Gruppe_55" data-name="Gruppe 55" transform="translate(0 0)"><g id="Gruppe_53" data-name="Gruppe 53" clip-path="url(#clip-path)"><path id="Pfad_86" data-name="Pfad 86" d="M5.767,25.7,18.441,13.027,5.767.354" fill="none" stroke="#da935c" stroke-miterlimit="10" stroke-width="1"></path><line id="Linie_10" data-name="Linie 10" x1="17.923" transform="translate(0 13.008)" fill="none" stroke="#da935c" stroke-miterlimit="10" stroke-width="1"></line></g></g></svg></a></div>');
		
		 
	}, 2000);
	$('.newsslider .slick-track').addClass('d-flex');
	$('.newsslider .slick-list').addClass('overflow-hidden');
	$('.customerslider .slick-track').addClass('d-flex');
	$('.customerslider .slick-list').addClass('overflow-hidden');
	$('.referenzenslider .slick-track').addClass('d-flex');
	$('.referenzenslider .slick-list').addClass('overflow-hidden');
	$('.slider-is-kundenliste .slick-track').addClass('d-flex align-items-center justify-content-center gap-100');
	
	$.fn.isInViewport = function() {
		var elementTop = $(this).offset().top;
		var elementBottom = elementTop + $(this).outerHeight();
	
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();
	
		return elementBottom > viewportTop && elementTop < viewportBottom;
	};
	
	var $timelineItem = $('.timeline .timeline-item');
	$(window).on('resize scroll', function() {
		$timelineItem.each(function( index ) {
			if ($(this).isInViewport()) {
				$(this).addClass('active').removeClass('inactive');
			} else {
				$(this).removeClass('active').addClass('inactive');
			}
		});
	});
	
	var $timelineItem = $('.block_tpw-headline-text .col-12.col-md-6');
	$(window).on('resize scroll', function() {
		$timelineItem.each(function( index ) {
			if ($(this).isInViewport()) { 
				$(this).find('.image-overlay').addClass('active').removeClass('inactive');
			} else {
				$(this).find('.image-overlay').removeClass('active').addClass('inactive');
			}
		});
	});
	
}); 